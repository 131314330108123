.project-card {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 6px solid #000;
    margin-bottom: 20px;
  }
  
  .project-card:hover {
    transform: scale(1.05);
  }
  
  .project-card-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .project-card-content {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  .project-card-title {
    font-size: 1.5rem;
    margin: 0;
  }
  
  .project-card-description {
    font-size: 1rem;
    color: #555;
  }
  
  .project-card-tech {
    font-size: 0.9rem;
    color: #666;
  }
  
  .project-card-links {
    display: flex;
    gap: 12px;
  }
  
  .project-card-link {
    text-decoration: none;
    color: #007bff;
    font-weight: bold;
    transition: color 0.2s ease;
  }
  
  .project-card-link:hover {
    color: #0056b3;
  }