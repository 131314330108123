/* Navbar Container */
.navbar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #000000;
    color: white;
    text-align: center;
    padding: 10px 0;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    height: 40px;
    font-family: "Audiowide", sans-serif;
  }
  
  /* Navbar Links */
  .navbar-links {
    list-style: none;
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 0;
    padding: 0;
  }
  
  .navbar-links a {
    text-decoration: none;
    color: white;
    font-weight: bold;
  }
  
  .navbar-links a:hover {
    color: #ff119c; /* Highlight color */
  }

  @media (min-width: 768px) {
  .navbar {
    position: static;
  }
}

@import url("https://use.fontawesome.com/releases/v6.4.2/css/all.css");

