/* Landing Page Container */
.landing-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: black;
  color: white;
  text-align: center;
  transition: opacity 1s ease-out; /* Smooth fade effect */
  opacity: 1;
  flex-direction: column;
  font-family: "Audiowide", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.landing-container.fade-out {
    opacity: 0; /* Fades out the entire container */
  }
  
  .landing-content h1 {
    font-size: 5rem;
    margin-bottom: 20px;
  }
  
  .landing-content p {
    font-size: 1rem;
    margin-bottom: 30px;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .landing-content2 {
    font-size: 24px;
    opacity: 0;
    animation: blink 2.5s infinite;
    color: #ff119c;
    animation-delay: 2s;
}

  @keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media (max-width: 1024px) {
    .landing-content2 {
      margin-top: 40%; /* Adjust for laptop screen */
      font-size: 18px; /* Smaller font for smaller screens */
    }
  
    .landing-content h1 {
      font-size: 4rem; /* Adjust main heading size */
    }
  
    .landing-content p {
      font-size: 0.9rem; /* Adjust subtext size */
    }
  }
  
  @media (max-width: 768px) {
    .landing-content2 {
      margin-top: 30%;
      font-size: 16px;
    }
  
    .landing-content h1 {
      font-size: 3rem;
    }
  
    .landing-content p {
      font-size: 0.8rem;
    }
  }
