.contactCard {
    width: 260px;
    padding: 20px;
    background: #fff;
    border: 6px solid #000;
    box-shadow: 12px 12px 0 #000;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .contactCard:hover {
    transform: translate(-5px, -5px);
    box-shadow: 17px 17px 0 #000;
  }
  
  .contactCard_title {
    font-size: 32px;
    font-weight: 900;
    color: #000;
    text-transform: uppercase;
    margin-bottom: 15px;
    display: block;
    position: relative;
    overflow: hidden;
  }
  
  .contactCard_title ::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 90%;
    height: 3px;
    background-color: #000;
    transform: translateX(-100%);
    transition: transform 0.3s;
  }
  
  .contactCard:hover .contactCard_title ::after {
    transform: translateX(0);
  }
  
  .card__content {
    font-size: 16px;
    line-height: 1.4;
    color: #000;
    margin-bottom: 20px;
  }
  
  .card__form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .card__form input, textarea {
    padding: 10px;
    border: 3px solid #000;
    font-size: 16px;
    font-family: inherit;
    transition: transform 0.3s;
    width: calc(100% - 26px); /* Adjust for padding and border */
  }
  
  .card__form textarea:focus, input:focus {
    outline: none;
    transform: scale(1.05);
    background-color: #000;
    color: #ff119c;
  }
  
  .card__button {
    border: 3px solid #000;
    background: #000;
    color: #fff;
    padding: 10px;
    font-size: 18px;
    left: 20%;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: transform 0.3s;
    width: 50%;
    height: 100%;
    font-family: inherit;
  }
  
  .card__button::before {
    content: "Done?";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 105%;
    background-color: #5ad641;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(100%);
    transition: transform 0.3s;
  }
  
  .card__button:hover::before {
    transform: translateY(0);
  }
  
  .card__button:active {
    transform: scale(0.95);
  }
  
  @keyframes glitch {
    0% {
      transform: translate(2px, 2px);
    }
    25% {
      transform: translate(-2px, -2px);
    }
    50% {
      transform: translate(-2px, 2px);
    }
    75% {
      transform: translate(2px, -2px);
    }
    100% {
      transform: translate(2px, 2px);
    }
  }
  
  .glitch {
    animation: glitch 0.3s infinite;
  }

.contactpage-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: white;
    text-align: center;
    transition: opacity 1s ease-out; /* Smooth fade effect */
    opacity: 1;
    flex-direction: column;
    font-family: "Audiowide", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  h1 {
    font-size: 3rem;
    margin-bottom: 20px;
  }
  
  p {
    font-size: 1.2rem;
  }